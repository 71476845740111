import React, { useRef } from 'react';
import { motion, useInView } from 'framer-motion';
import { H1, H2 } from '../components/Headings';
import CTA from '../components/CTA';
import { FaCode, FaDatabase, FaReact, FaDocker, FaLinux, FaWordpress, FaSymfony, FaBullhorn } from 'react-icons/fa';
import illustrationWebDeveloper from '../assets/images/illustrations/illustration-web-developer.svg';
import illustrationAnalyse from '../assets/images/illustrations/illustration-analyse.svg';
import illustrationSelectSolution from '../assets/images/illustrations/illustration-select-solution.svg';
import Section from '../components/Section';

// Compétences avec icônes
const skillsData = [
  { icon: <FaCode size={40} className="text-brun" />, title: 'HTML5 / CSS3 / JavaScript', description: "Expertise dans les langages de programmation essentiels pour la création de sites modernes." },
  { icon: <FaDatabase size={40} className="text-brun" />, title: 'PHP / MySQL', description: "Gestion de bases de données robustes et développement de fonctionnalités back-end." },
  { icon: <FaReact size={40} className="text-brun" />, title: 'React / Tailwind CSS', description: "Développement d'interfaces utilisateur modernes avec React.js et Tailwind CSS." },
  { icon: <FaDocker size={40} className="text-brun" />, title: 'Docker', description: "Conteneurisation des applications pour une gestion simplifiée du déploiement." },
  { icon: <FaLinux size={40} className="text-brun" />, title: 'Linux / Windows', description: "Administration de systèmes Linux et Windows pour assurer le bon fonctionnement des serveurs." },
  { icon: <FaWordpress size={40} className="text-brun" />, title: 'WordPress / PrestaShop / Shopify', description: "Expertise dans la création et la personnalisation de sites e-commerce." },
  { icon: <FaSymfony size={40} className="text-brun" />, title: 'Symfony', description: "Développement d'applications web complexes avec le framework PHP Symfony." },
  { icon: <FaBullhorn size={40} className="text-brun" />, title: 'Google Ads / Landing Page', description: "Conception de campagnes Google Ads et landing pages optimisées." }
];

// Composant pour afficher les compétences avec animation
const SkillsSection = ({ title, skills, disablePaddingTop = false, disablePaddingBottom = false }) => {
  const sectionRef = useRef(null);
  const isInView = useInView(sectionRef, { once: true });

  return (
    <Section disablePaddingTop={disablePaddingTop} disablePaddingBottom={disablePaddingBottom}>
      <motion.div
        ref={sectionRef}
        initial={{ opacity: 0, y: 50 }}
        animate={isInView ? { opacity: 1, y: 0 } : {}}
        transition={{ duration: 1.2 }}
      >
        <H2 className="text-white">{title}</H2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mb-8">
          {skills.map((skill, index) => (
            <motion.div
              key={index}
              className="bg-white shadow-lg rounded-lg p-6 text-center flex flex-col items-center"
              initial={{ opacity: 0, y: 50 }}
              animate={isInView ? { opacity: 1, y: 0 } : {}}
              transition={{ duration: 0.8, delay: index * 0.2 }}
            >
              <div className="flex justify-center items-center mb-4">
                {skill.icon}
              </div>
              <h3 className="text-xl font-bold text-camel mb-2">{skill.title}</h3>
              <p>{skill.description}</p>
            </motion.div>
          ))}
        </div>
      </motion.div>
    </Section>
  );
};

const About = () => {
  const section1Ref = useRef(null);
  const section2Ref = useRef(null);
  const section3Ref = useRef(null);
  
  const isInViewSection1 = useInView(section1Ref, { once: true });
  const isInViewSection2 = useInView(section2Ref, { once: true });
  const isInViewSection3 = useInView(section3Ref, { once: true });

  return (
    <div>
      {/* Section Présentation */}
      <Section bgColor="bg-white" disablePaddingBottom={true}>
        <motion.div
          ref={section1Ref}
          className="flex flex-col items-center justify-between lg:flex-row-reverse"
          initial={{ opacity: 0, y: 50 }}
          animate={isInViewSection1 ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 1.2 }}
        >
          <div className="lg:w-1/2 flex justify-center items-center mb-8 lg:mb-0">
            <img
              src={illustrationWebDeveloper}
              alt="Illustration web developer"
              className="max-w-full h-auto w-32 lg:w-80"
            />
          </div>

          <div className="lg:w-1/2 lg:text-left mb-8 lg:mb-0">
            <H1 className="text-4xl sm:text-5xl font-bold text-camel text-center mb-8 lg:text-left lg:mb-12">
              À propos de moi
            </H1>
            <p className="text-lg mb-6">
              Je m'appelle <strong>Sofiane</strong>, développeur web freelance
              spécialisé dans la conception de solutions web sur mesure. Fort de
              plus de 6 ans d'expérience, je m'efforce de créer des sites
              modernes, fonctionnels et performants pour répondre aux exigences
              spécifiques de chaque projet. Mon approche est centrée sur
              l'utilisateur, avec une attention particulière à l'optimisation
              des performances et du <strong>référencement naturel (SEO)</strong>.
            </p>
          </div>
        </motion.div>
      </Section>

      {/* Section Analyse */}
      <Section bgColor="bg-white" disablePaddingTop={true} disablePaddingBottom={true}>
        <motion.div
          ref={section2Ref}
          className="flex flex-col items-center justify-between lg:flex-row-reverse"
          initial={{ opacity: 0, y: 50 }}
          animate={isInViewSection2 ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 1.2 }}
        >
          <div className="lg:w-1/2 lg:text-left mb-8 lg:mb-0 order-2 lg:order-1">
            <h2 className="text-xl">Un passionné web à votre service</h2>
            <p className="text-lg mb-6">
              Ma passion pour les nouvelles technologies m'amène à rester en
              veille constante sur les dernières tendances du web, garantissant
              à mes clients des solutions à la pointe de l'innovation. En
              alliant <strong>développement web</strong> et <strong>stratégies digitales</strong>, je contribue à renforcer la
              présence en ligne de mes clients.
            </p>
            <h2 className="text-xl">
              Expertise en développement web personnalisée
            </h2>
            <p className="text-lg mb-6">
              Mon expérience acquise au fil des projets me permet de mieux
              comprendre les attentes d'un client et de répondre précisément au
              besoin demandé en fonction du domaine d'activité.
            </p>
          </div>
          <div className="lg:w-1/2 flex justify-center items-center mb-8 lg:mb-0 order-1 lg:order-2">
            <img
              src={illustrationAnalyse}
              alt="Illustration site web"
              className="max-w-full h-auto w-32 lg:w-80"
            />
          </div>
        </motion.div>
      </Section>

      {/* Section Solutions */}
      <Section bgColor="bg-white" disablePaddingTop={true}>
        <motion.div
          ref={section3Ref}
          className="flex flex-col items-center justify-between lg:flex-row-reverse"
          initial={{ opacity: 0, y: 50 }}
          animate={isInViewSection3 ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 1.2 }}
        >
          <div className="lg:w-1/2 flex justify-center items-center mb-8 lg:mb-0">
            <img
              src={illustrationSelectSolution}
              alt="Illustration selectionner solution"
              className="max-w-full h-auto w-32 lg:w-80"
            />
          </div>
          <div className="lg:w-1/2 lg:text-left mb-8 lg:mb-0">
            <h2 className="text-xl">Un site à votre image, pensé pour votre audience</h2>
            <p className="text-lg mb-6">
              Mon objectif : vous fournir un site web qui non seulement reflète
              votre image, mais qui attire aussi votre audience cible tout en
              offrant une expérience utilisateur fluide et engageante.
            </p>
            <h2 className="text-xl">Des solutions adaptées à votre budget</h2>
            <p className="text-lg mb-6">
              Habitué à travailler avec des entrepreneurs et des particuliers,
              je propose des solutions web flexibles et adaptées à vos objectifs,
              tout en respectant votre budget.
            </p>
          </div>
        </motion.div>
        <div className="text-center px-4 md:px-8 lg:px-16 lg:pt-16">
          <CTA className="inline bg-cta text-white hover:bg-white" text="Démarrer mon projet" link="/contact" />
        </div>
      </Section>

      {/* Section Compétences Techniques */}
      <SkillsSection title="Mes compétences techniques" skills={skillsData.slice(0, 3)} disablePaddingBottom={true}/>

      {/* Section Compétences Supplémentaires */}
      <SkillsSection title="Compétences supplémentaires" skills={skillsData.slice(3)} disablePaddingTop={true} />
    </div>
  );
};

export default About;
