import React from 'react';
import Section from '../components/Section';
import illustrationContact from '../assets/images/illustrations/illustration-discuss-your-project.svg'; // Assurez-vous de remplacer par le bon chemin de l'image

const Contact = () => {
  return (
    <div className="bg-background text-text lg:pt-12">
      <Section bgColor="bg-white">
        {/* Conteneur en flexbox pour aligner le texte, l'image et le formulaire */}
        <div className="flex flex-col lg:flex-row items-center lg:items-start lg:justify-between gap-12">
          {/* Partie gauche : Image, Titre et Paragraphe */}
          <div className="lg:w-1/2 flex flex-col items-center lg:items-start">
            {/* Image */}
            <img
              src={illustrationContact}
              alt="Illustration contact"
              className="max-w-full h-auto w-64 lg:w-80 mb-8 lg:mb-4"
            />

            {/* Titre h1 */}
            <h1 className="text-4xl font-bold text-camel text-center lg:text-left mb-8 lg:mb-4 animate-fadeIn">
              Contactez-moi pour discuter de votre projet web
            </h1>

            {/* Paragraphe */}
            <p className="text-lg text-center lg:text-left mb-8 lg:mb-4 animate-slideUp">
              Vous avez un projet en tête, que ce soit la création d’un site
              vitrine, e-commerce ou une stratégie SEO ? Je suis à votre
              disposition pour échanger et vous aider à réaliser votre vision.
              Remplissez le formulaire ci-dessous et je vous recontacterai
              rapidement.
            </p>
          </div>

          {/* Formulaire à droite en desktop, en dessous du texte en mobile */}
          <div className="lg:w-1/2">
            <form
              className="max-w-lg mx-auto lg:mx-0 bg-white shadow-lg rounded-lg p-8 animate-fadeIn"
              action="/envoyer"
              method="POST"
            >
              <div className="mb-6">
                <label
                  className="block text-camel text-sm font-bold mb-2"
                  htmlFor="name"
                >
                  Nom
                </label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="name"
                  name="name"
                  type="text"
                  placeholder="Votre nom"
                  required
                />
              </div>
              <div className="mb-6">
                <label
                  className="block text-camel text-sm font-bold mb-2"
                  htmlFor="email"
                >
                  Email
                </label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="email"
                  name="email"
                  type="email"
                  placeholder="Votre email"
                  required
                />
              </div>
              <div className="mb-6">
                <label
                  className="block text-camel text-sm font-bold mb-2"
                  htmlFor="message"
                >
                  Message
                </label>
                <textarea
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="message"
                  name="message"
                  placeholder="Décrivez votre projet ou votre demande"
                  rows="5"
                  required
                />
              </div>
              <button
                className="bg-cta text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline hover:bg-camel hover:text-white transition duration-300 w-full"
                type="submit"
              >
                Envoyer ma demande
              </button>
            </form>
            <div className="mt-12 text-center lg:text-left">
              <p className="text-md">
                Ou contactez-moi directement à{' '}
                <strong>contact@sofiane.ovh</strong> pour discuter de votre
                projet.
              </p>
            </div>
          </div>
        </div>
      </Section>
    </div>
  );
};

export default Contact;
