import React, { useRef } from 'react';
import { motion, useInView } from 'framer-motion';
import Section from '../components/Section'; // Utilisation du composant Section
import { H1, H2 } from '../components/Headings';
import Card from '../components/Card'; // Composant réutilisable pour les projets
import illustrationProjects from '../assets/images/illustrations/illustration-responsive.svg'; // Image illustrative pour la section projets

// Données des projets
const projectsData = [
  {
    title: "L'Élégance Coiffure",
    description:
      'Site vitrine pour un salon de coiffure féminin à Paris, présentant les services, la prise de rendez-vous en ligne et une galerie de réalisations.',
    image: '/images/project-coiffure.jpg', // Image de démonstration
    link: '/projects/elegance-coiffure',
    seoDescription:
      "Création d'un site vitrine pour L'Élégance Coiffure, un salon de coiffure à Paris, spécialisé dans les coiffures modernes pour femmes.",
  },
  {
    title: "Les P'tits Anges",
    description:
      'Site vitrine pour une assistante maternelle, mettant en avant ses services, son expérience, et les avis des parents.',
    image: '/images/project-assistante.jpg',
    link: '/projects/ptits-anges',
    seoDescription:
      "Développement d'un site vitrine pour une assistante maternelle, avec des informations sur les services, la sécurité des enfants, et des témoignages.",
  },
  {
    title: 'Cabinet Juridique JD',
    description:
      "Site vitrine pour un cabinet d'avocats, spécialisé en droit des affaires et droit du travail, avec une présentation des services et une prise de contact.",
    image: '/images/project-avocat.jpg',
    link: '/projects/cabinet-jd',
    seoDescription:
      'Site vitrine pour le cabinet juridique JD, spécialisé dans le droit des affaires et le droit du travail, avec des services détaillés et une prise de rendez-vous.',
  },
  {
    title: 'Beauté Coiffure',
    description:
      'Site e-commerce pour la vente de produits de coiffure professionnels avec un système de gestion des commandes et des promotions.',
    image: '/images/project-ecommerce-coiffure.jpg',
    link: '/projects/beaute-coiffure',
    seoDescription:
      "Création d'un site e-commerce pour Beauté Coiffure, spécialisé dans la vente de produits professionnels pour salons de coiffure.",
  },
  {
    title: 'Pattes et Câlins',
    description:
      'Site e-commerce pour la vente de produits pour chiens, avec une boutique en ligne complète et des conseils pour les soins animaliers.',
    image: '/images/project-ecommerce-chiens.jpg',
    link: '/projects/pattes-calin',
    seoDescription:
      "Développement d'un site e-commerce pour Pattes et Câlins, spécialisé dans les produits pour chiens, avec des fiches produits et des conseils d'experts.",
  },
];

const Projects = () => {
  // Ref et état de visibilité pour déclencher l'animation au scroll
  const sectionRef = useRef(null);
  const isInView = useInView(sectionRef, { once: true });

  return (
    <Section bgColor="bg-white">
      {/* Grille avec texte à gauche et image à droite */}
      <motion.div
        className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center"
        initial={{ opacity: 0, y: 50 }}
        animate={isInView ? { opacity: 1, y: 0 } : {}}
        transition={{ duration: 1.2 }}
        ref={sectionRef}
      >
        {/* Colonne pour le texte */}
        <motion.div
          initial={{ opacity: 0, x: -50 }}
          animate={isInView ? { opacity: 1, x: 0 } : {}}
          transition={{ duration: 1.2 }}
          className="text-center lg:text-left order-2 lg:order-1"
        >
          <H1
            className="text-4xl lg:text-5xl font-bold text-camel mb-8"
            disableTextCenter={true}
          >
            Mes projets
          </H1>
          <p className="text-lg text-left mb-6">
            Découvrez mes récents projets de développement web, allant des sites
            vitrines modernes aux solutions e-commerce sur mesure. Chaque projet
            est conçu pour répondre aux besoins spécifiques de mes clients et
            maximiser leur présence en ligne.
          </p>
        </motion.div>

        {/* Colonne pour l'image */}
        <motion.div
          initial={{ opacity: 0, x: 50 }}
          animate={isInView ? { opacity: 1, x: 0 } : {}}
          transition={{ duration: 1.2 }}
          className="order-1 lg:order-2 flex justify-center lg:justify-end"
        >
          <img
            src={illustrationProjects}
            alt="Illustration des projets"
            className="max-w-full h-auto w-32 lg:w-9/12"
          />
        </motion.div>
      </motion.div>

      {/* Section des projets */}
      <motion.div
        initial={{ opacity: 0, y: 50 }}
        animate={isInView ? { opacity: 1, y: 0 } : {}}
        transition={{ duration: 1.2 }}
        className="mt-12"
      >
        <H2 className="text-camel">Ils m'ont fait confiance</H2>
        <p className="text-lg mb-12 text-center">
          Voici quelques exemples de projets que j'ai réalisés pour mes clients,
          couvrant divers secteurs d'activité.
        </p>

        {/* Affichage des cartes des projets */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {projectsData.map((project, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 50 }}
              animate={isInView ? { opacity: 1, y: 0 } : {}}
              transition={{ duration: 0.8, delay: index * 0.2 }}
              className="flex flex-col h-full"
            >
              <div className="bg-white shadow-lg rounded-lg p-6 text-center flex flex-col justify-between h-full">
                <div>
                  <img
                    src={project.image}
                    alt={project.title}
                    className="mb-4 h-48 w-full object-cover rounded-lg"
                  />
                  <h3 className="text-xl font-bold text-camel mb-2">
                    {project.title}
                  </h3>
                  <p className="text-md text-gray-600 mb-4">
                    {project.description}
                  </p>
                </div>
                <a
                  href={project.link}
                  className="text-brun font-bold hover:underline mt-auto"
                >
                  En savoir +
                </a>
              </div>
            </motion.div>
          ))}
        </div>
      </motion.div>
    </Section>
  );
};

export default Projects;
