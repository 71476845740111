import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/images/logo-transparent.png';
import Section from '../components/Section';
import { H1, H2, H3 } from '../components/Headings';
import CTA from './CTA';

const Footer = () => {
  return (
    <>
      {/* Section "Prêt à collaborer ?" sur fond blanc avec bordure camel */}
      <Section bgColor="bg-white">
        <H2>Prêt à collaborer ?</H2>
        <p className="text-lg mb-12 text-center animate-fadeIn">
          Je suis disponible pour de nouveaux projets. N'hésitez pas à me
          contacter pour discuter de vos besoins en développement web, création
          de site e-commerce, ou optimisation SEO.
        </p>
        <div class="text-center">
          <CTA
            className="inline bg-camel text-white hover:bg-brun-fonce"
            text="Contactez-moi"
            link="/contact"
          />
        </div>
      </Section>

      {/* Section principale du footer */}
      <footer className="bg-camel text-white py-16">
        <div className="container mx-auto grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
          {/* Logo et description */}
          <div className="px-4">
            <Link to="/" title="Retour à l'accueil">
              <img
                src={logo}
                alt="Logo Sofiane Développeur Web"
                className="h-20"
              />{' '}
              {/* Remplace par ton logo */}
            </Link>
            <p className="text-lg mt-4">
              Sofiane - Développeur web freelance expert en{' '}
              <strong>création de sites web sur mesure</strong>, optimisation
              SEO et <strong>stratégies digitales</strong>.
            </p>
          </div>

          {/* Navigation */}
          <nav className="px-4" aria-label="Navigation du site">
            <p className="text-2xl text-white font-bold mb-4">Navigation</p>
            <ul className="space-y-2">
              <li>
                <Link
                  to="/"
                  className="hover:text-brun-fonce"
                  title="Retour à l'accueil"
                >
                  Accueil
                </Link>
              </li>
              <li>
                <Link
                  to="/projects"
                  className="hover:text-brun-fonce"
                  title="Voir mes projets"
                >
                  Projets
                </Link>
              </li>
              <li>
                <Link
                  to="/services"
                  className="hover:text-brun-fonce"
                  title="Découvrir mes services"
                >
                  Services
                </Link>
              </li>
              <li>
                <Link
                  to="/about"
                  className="hover:text-brun-fonce"
                  title="En savoir plus sur moi"
                >
                  À propos
                </Link>
              </li>
              <li>
                <Link
                  to="/contact"
                  className="hover:text-brun-fonce"
                  title="Me contacter"
                >
                  Contact
                </Link>
              </li>
            </ul>
          </nav>

          {/* Services */}
          <div className="px-4">
            <p className="text-2xl text-white font-bold mb-4">Services</p>
            <ul className="space-y-2">
              <li>
                <Link to="/services#web-development" title="Développement web">
                  Développement web
                </Link>
              </li>
              <li>
                <Link to="/services#seo" title="SEO et marketing digital">
                  SEO & Marketing digital
                </Link>
              </li>
              <li>
                <Link
                  to="/services#digital-strategy"
                  title="Stratégie digitale"
                >
                  Identité visuelle & support print
                </Link>
              </li>
              <li>
                <Link
                  to="/services#maintenance"
                  title="Maintenance informatique"
                >
                  Maintenance informatique
                </Link>
              </li>
            </ul>
          </div>

          {/* Contact */}
          <address className="px-4 not-italic">
            <p className="text-2xl text-white font-bold mb-4">Contact</p>
            <p>Bordeaux & périphérie, 33000</p>
            <p>Bordeaux, France</p>
            <p>
              Email :{' '}
              <a
                href="mailto:contact@sofiane.dev"
                className="underline hover:text-brun-fonce"
              >
                contact@sofiane.ovh
              </a>
            </p>
          </address>
        </div>
      </footer>

      {/* Section des mentions légales et copyright */}
      <div className="bg-camel text-white py-4 border-t-[1px] border-white">
        <div className="container mx-auto text-center">
          <p className="font-bold mb-2">
            &copy; 2024 Sofiane. Tous droits réservés.
          </p>
          <Link
            to="/mentions-legales"
            className="underline hover:text-brun-fonce"
            title="Voir les mentions légales"
          >
            Mentions légales
          </Link>
        </div>
      </div>
    </>
  );
};

export default Footer;
