import React, { useRef } from 'react';
import { motion, useInView } from 'framer-motion';
import {
  FaCode,
  FaSearch,
  FaBullhorn,
  FaCog,
  FaCheckCircle,
} from 'react-icons/fa';
import illustrationServices from '../assets/images/illustrations/illustration-services.svg';
import SliderComponent from '../components/SliderComponent';
import Section from '../components/Section';
import { H1, H2 } from '../components/Headings';
import CTA from '../components/CTA';

const servicesData = [
  {
    title: 'Développement web sur mesure',
    description:
      'Création de sites vitrines, e-commerce et applications web sur mesure.',
    details:
      'Chaque projet est conçu pour répondre à vos besoins spécifiques, en utilisant des technologies modernes telles que React, WordPress, et Shopify.',
    icon: <FaCode size={50} className="text-brun" />,
  },
  {
    title: 'SEO & Marketing digital',
    description:
      'Optimisation pour les moteurs de recherche et stratégies marketing digital.',
    details:
      'Je vous aide à améliorer votre visibilité, attirer plus de trafic qualifié grâce à une stratégie SEO complète et des campagnes marketing.',
    icon: <FaSearch size={50} className="text-brun" />,
  },
  {
    title: 'Stratégie digitale',
    description: 'Stratégies digitales pour accroître votre présence en ligne.',
    details:
      'Développement de stratégies adaptées pour atteindre vos objectifs de croissance, y compris la gestion des réseaux sociaux et la publicité en ligne.',
    icon: <FaBullhorn size={50} className="text-brun" />,
  },
  {
    title: 'Maintenance & Support technique',
    description: 'Services de maintenance informatique et support technique.',
    details:
      'Service de maintenance proactive pour assurer la sécurité et la stabilité de vos infrastructures techniques.',
    icon: <FaCog size={50} className="text-brun" />,
  },
];

const Services = () => {
  const section3Ref = useRef(null);
  const isInViewSection3 = useInView(section3Ref, { once: true });

  const sectionCollaborativeRef = useRef(null);
  const isInViewCollaborative = useInView(sectionCollaborativeRef, {
    once: true,
    threshold: 0.2,
  });

  const carouselSettings = {
    dots: false,
    infinite: true,
    speed: 1600,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div>
      {/* Section 1: Introduction */}
      <Section bgColor="bg-white">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
          <motion.div
            className="flex justify-center items-center lg:mb-0"
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 1.2 }}
          >
            <img
              src={illustrationServices}
              alt="Illustration services"
              className="max-w-full h-auto w-64 lg:w-80"
            />
          </motion.div>

          <motion.div
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 1.2 }}
          >
            <h1 className="text-4xl lg:text-5xl font-bold text-camel mb-8">
              Services de développement web et stratégie digitale personnalisée
            </h1>
            <p className="text-lg text-gray-700 mb-6">
              En tant que développeur web freelance, je propose des services
              variés tels que la création de sites web, l'optimisation SEO, et
              l'élaboration de stratégies digitales. Mon objectif est de vous
              fournir des solutions adaptées pour atteindre vos objectifs et
              maximiser votre visibilité en ligne.
            </p>
            <p className="text-lg text-gray-700 mb-6 font-bold">
              N'hésitez pas à me contacter pour toute demande de collaboration.
            </p>
            <div className="text-center px-4 md:px-8 lg:px-16 pt-8">
              <CTA
                className="inline bg-cta text-white hover:bg-white"
                text="Contactez-moi"
                link="/contact"
              />
            </div>
          </motion.div>
        </div>
      </Section>

      {/* Section 2: Carousel des Services */}
      <Section>
        <div className="container mx-auto lg:px-32">
          <H2 className="text-white">Les services que je propose</H2>
          <SliderComponent
            servicesData={servicesData}
            carouselSettings={carouselSettings}
          />
        </div>
      </Section>

      {/* Section 3: Image de marque sur mesure */}
      <Section bgColor="bg-white">
        <motion.div
          ref={section3Ref}
          className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center"
          initial={{ opacity: 0, x: -50 }}
          animate={isInViewSection3 ? { opacity: 1, x: 0 } : {}}
          transition={{ duration: 1.2 }}
        >
          <div>
            <h2 className="text-4xl sm:text-5xl font-bold text-camel mb-6">
              Optimisation de l'expérience utilisateur et performance web
            </h2>
            <p className="text-lg text-gray-700 mb-6">
              J'améliore la performance de votre site web pour qu'il soit à la
              fois plus rapide, plus fiable et plus agréable à utiliser pour vos
              clients. Cela signifie des temps de chargement rapides, une
              navigation fluide et un site accessible sur tous les appareils
              (ordinateur, tablette, smartphone).
            </p>
            <p className="text-lg text-gray-700 mb-6 font-bold">
              Un site rapide et fiable permet d'augmenter le taux de conversion
              et d'améliorer la satisfaction des utilisateurs, tout en vous
              offrant une meilleure visibilité en ligne.
            </p>
            <div className="text-center px-4 md:px-8 lg:px-16 pt-8">
              <CTA
                className="inline bg-cta text-white hover:bg-white"
                text="Voir mes projets"
                link="/projects"
              />
            </div>
          </div>

          <motion.div
            className="bg-gray-100 p-6 rounded-lg"
            initial={{ opacity: 0, x: 50 }}
            animate={isInViewSection3 ? { opacity: 1, x: 0 } : {}}
            transition={{ duration: 1.2 }}
          >
            <h3 className="text-2xl font-bold mb-4">
              Pourquoi c'est important ?
            </h3>
            <ul className="list-disc text-left pl-4 space-y-2">
              <li>
                Temps de chargement rapide = plus de clients restent sur le site
              </li>
              <li>Une navigation fluide améliore l'expérience utilisateur</li>
              <li>
                Un site fiable crée la confiance et évite les interruptions
              </li>
              <li>
                Optimisation pour mobile et tablette pour toucher un plus large
                public
              </li>
            </ul>
            <div className="text-center px-4 md:px-8 lg:px-16 pt-8">
              <CTA
                className="inline bg-cta text-white hover:bg-white"
                text="Discutons de votre projet"
                link="/contact"
              />
            </div>
          </motion.div>
        </motion.div>
      </Section>

      {/* Nouvelle section : Méthode de travail collaboratif */}
      <Section>
        <motion.div
          ref={sectionCollaborativeRef}
          className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center"
          initial={{ opacity: 0, y: 50 }}
          animate={isInViewCollaborative ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 1.2 }}
        >
          <motion.div
            initial={{ opacity: 0, x: -50 }}
            animate={isInViewCollaborative ? { opacity: 1, x: 0 } : {}}
            transition={{ duration: 1.2 }}
          >
            <h2 className="text-3xl lg:text-4xl font-bold text-white mb-4">
              Une méthode de travail collaboratif
            </h2>
            <p className="text-lg text-white mb-6">
              L'objectif de notre travail en tandem est la maximisation du
              retour sur investissement. En effet, tout l’intérêt est de vous
              permettre de générer des résultats bénéfiques à votre business.
              Plusieurs étapes cruciales s’inscrivent dans ce processus
              collaboratif.
            </p>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, x: -50 }}
            animate={isInViewCollaborative ? { opacity: 1, x: 0 } : {}}
            transition={{ duration: 1.2 }}
            className="space-y-4"
          >
            {[
              'Sens du résultat',
              'Relation humaine et pro',
              'Conscience professionnelle',
              'Disponibilité & Facilité',
              'Accompagnement sur mesure',
            ].map((text, index) => (
              <motion.div
                key={index}
                className="flex items-center"
                initial={{ opacity: 0, y: 50 }}
                animate={isInViewCollaborative ? { opacity: 1, y: 0 } : {}}
                transition={{ duration: 0.8, delay: index * 0.2 }}
              >
                <FaCheckCircle size={24} className="text-olive mr-3" />
                <span className="text-lg text-white font-extrabold">{text}</span>
              </motion.div>
            ))}
          </motion.div>
        </motion.div>
      </Section>
    </div>
  );
};

export default Services;
